.ChattingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.ChattingCopy {
    width: 45%;
    margin-right: 10vh;
}

.ChattingGraphics {
    width: 65%;
}


.ChattingCopyWhiteSquare {
    width: fit-content;
    padding: 0 6px 0 6px;
    border-radius: 5px;
    border-color: aliceblue;
    border-style:solid;
    background-color: white;
    font-family: 'Roboto';
    font-size:xxx-large;
    font-weight: 700;
    margin-bottom: 25px;
}

.ChattingCopyTitle {
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-align:start;
    margin-bottom: 10px;
}

.ChattingCopyDescription {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 150%;
    color: #c5c5c5;
    text-align:start;
}

@media only screen and (max-width: 1400px) {
    .ChattingCopy {
        width: 30%;
        margin-left: 10vh;
    }
    
    .ChattingGraphics {
        width: 70%;
    }

    .ChattingCopyTitle {
        font-size: 25px;
    }

    .ChattingCopyDescription{
        font-size: 20px;
    }
}

@media only screen and (max-width: 820px) {
    .ChattingContainer {
        height: 80%;
        margin-top: 150px;
    }

    .ChattingCopy {
        width: 90%;
        margin-right: 3vh;
    }

    .ChattingGraphics {
        width: 95%;
    }
}

@media only screen and (max-width: 720px)  {
    .ChattingContainer {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 80%;
        margin-top: 130px;
    }

    .ChattingCopy {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .ChattingGraphics {
        width: 100%;
    }

    .ChattingCopyWhiteSquare {
        font-size:xx-large;
        margin-left: auto;
        margin-right: auto;
    }

    .ChattingCopyTitle {
        font-size: 24px;
        text-align: center
    }

    .ChattingCopyDescription {
        text-align: center;
    }
}