.AIChatingWithFriendsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65vh;
}

.AIChatingWithFriendsCopy {
    width: 45%;
    margin-right: 10vh;
}

.AIChatingWithFriendsGraphics {
    width: 65%;
}

.AIChatingWithFriendsCopyWhiteSquare {
    width: fit-content;
    padding: 0 6px 0 6px;
    border-radius: 5px;
    border-color: aliceblue;
    border-style:solid;
    background-color: white;
    font-family: 'Roboto';
    font-size:xxx-large;
    font-weight: 700;
    margin-bottom: 25px;
}

.AIChatingWithFriendsCopyTitle {
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-align:start;
    margin-bottom: 10px;
}

.AIChatingWithFriendsCopyDescription {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 150%;
    color: #c5c5c5;
    text-align:start;
}

@media only screen and (max-width: 1400px) {
    .AIChatingWithFriendsCopy {
        width: 30%;
        margin-left: 10vh;
    }
    
    .AIChatingWithFriendsGraphics {
        width: 70%;
    }

    .AIChatingWithFriendsCopyTitle {
        font-size: 25px;
    }

    .AIChatingWithFriendsCopyDescription{
        font-size: 20px;
    }
}

@media only screen and (max-width: 820px) {
    .AIChatingWithFriendsContainer {
        height: 80%;
        margin-top: 150px;
    }

    .AIChatingWithFriendsCopy {
        width: 90%;
        margin-right: 5vh;
    }

    .AIChatingWithFriendsGraphics {
        width: 90%;
    }
}

@media only screen and (max-width: 720px)  {
    .AIChatingWithFriendsContainer {
        display: flex;
        flex-direction: column;
        /* gap: 2rem; */
        height: 80%;
        margin-top: 130px;
    }

    .AIChatingWithFriendsCopy {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .AIChatingWithFriendsGraphics {
        width: 90%;
    }

    .AIChatingWithFriendsCopyWhiteSquare {
        font-size:xx-large;
        margin-left: auto;
        margin-right: auto;
    }

    .AIChatingWithFriendsCopyTitle {
        font-size: 24px;
        text-align: center
    }

    .AIChatingWithFriendsCopyDescription {
        text-align: center;
    }
}