.HeroContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.HeroGraphics {
    width: 50%;
}

.HeroCopy {
    width: 50%;
}

.HeroCopyWhiteSquare {
    text-align: center;
    width: fit-content;
    padding: 0 6px 0 6px;
    border-radius: 5px;
    border-color: aliceblue;
    border-style:solid;
    background-color: white;
    font-family: 'Roboto';
    font-size:xxx-large;
    font-weight: 500;
}

.HeroCopyTitle {
    font-family: 'Roboto';
    font-weight: 800;
    font-size: 45px;
    line-height: 115%;
    color: #FFFFFF;
    margin-top: 10px;
    text-align: left;
}

@media only screen and (max-width: 1270px) {
    .HeroCopyWhiteSquare {
        font-size:xx-large;
        width: fit-content;
        padding: 0 6px 0 6px;
    }

    .HeroCopyTitle {
        font-size: 45px;
    }
}

@media only screen and (max-width: 940px) {
    .HeroCopyWhiteSquare {
        font-size:x-large;
    }

    .HeroCopyTitle {
        font-size: 35px;
    }
}

@media only screen and (max-width: 820px) {
    .HeroContainer {
        height: 80%;
        margin-top: 150px;
    }
}


@media only screen and (max-width: 720px) {
    .HeroContainer {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        /* height: 100vh; */
        height: 80%;
        margin-top: 100px;
    }

    .HeroGraphics{
        width: 80%;
    }

    .HeroCopy {
        width: 90%;
    }
    
    .HeroCopyWhiteSquare {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        padding: 0 6px 0 6px;
        font-size:x-large;
    }

    .HeroCopyTitle {
        font-size: 30px;
        text-align: center;
    }

}

@media only screen and (max-width: 360px) {
 
    .HeroCopyWhiteSquare {
        font-size:large;
    }

    .HeroCopyTitle {
        font-size: 25px;
        text-align: center;
    }
}